<template>
  <v-container>
    <div class="back-plot">
      <!-- BOC:[error] -->
      <ApiErrorDialog v-if="api.isError" :api="api" />
      <ErrorDialog
        v-if="error"
        :error="error"
        :message="errorMessage"
        @exitError="extiError"
      />
      <!-- EOC -->
      <!-- BOC -->
      <v-card class="transparent" elevation="0">
        <v-card-title class="grey lighten-5 rounded-lg py-2">
          <v-row>
            <v-col cols="9" class="d-flex rounded align-center">
              <AbstractAvatar :avatar="auth.Player.avatar" :user="true" />
              <div class="ps-2 pt-2">{{ auth.User.name }}</div>
            </v-col>
            <v-col cols="3" class="d-flex align-center justify-end">
              <CloseBtn size="40" :callback="exit" />
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
      <!-- EOC -->
      <div>
        <v-card
          class="mb-3 px-3 py-5"
          :class="
            settings.graphics && settings.graphics.mode == 'low'
              ? 'low_plot'
              : 'plot'
          "
        >
          <v-card-title>{{
            $t("view.PageEntranceStudentPasswordSelect.title")
          }}</v-card-title>
          <v-card-text>
            <v-row class="mt-4">
              <v-col v-for="(item, i) in items" :key="i" cols="3">
                <div
                  @click="selectKey(item.value)"
                  :class="
                    selectedKey == item.value ? 'green lighten-3 pa-3' : 'pa-3'
                  "
                  class="rounded-lg"
                  style="cursor: pointer"
                >
                  <v-img :src="require('@/assets/key/' + item.image)"></v-img>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="green lighten-1"
              large
              @click="next"
              :loading="api.isLoading"
              block
              rounded
              class="text-h6"
              style="text-transform: capitalize"
            >
              {{ $t("action.next") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import AvatarSkeleton from "@/components/skeleton/Avatar";
let click_sfx_1 = new Audio(require("@/assets/sounds/sfx_click_5.wav"));
let click_sfx_2 = new Audio(require("@/assets/sounds/sfx_click_6.wav"));
let click_sfx_3 = new Audio(require("@/assets/sounds/sfx_click_7.wav"));
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
    ErrorDialog: () =>
      import(
        /* webpackChunkName: "component-error-dialog" */ "@/components/ErrorDialog.vue"
      ),
    AbstractAvatar: () => ({
      component: import(
        /* webpackChunkName: "component-abstract-avatar-testing" */ "@/components/avatar/AbstractAvatar"
      ),
      loading: AvatarSkeleton,
      delay: 200,
    }),
  },
  computed: mapState({
    auth: (state) => state.auth.data,
    settings: (state) => state.settings.data,
  }),
  props: [
    //
  ],
  data: () => ({
    error: false,
    errorMessage: null,
    selectedKey: null,
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    items: [
      {
        image: "key 1.png",
        value: "redkey",
      },
      {
        image: "key 2.png",
        value: "yellowkey",
      },
      {
        image: "key 3.png",
        value: "bluekey",
      },
      {
        image: "key 4.png",
        value: "greenkey",
      },
    ],
    form: {
      userId: null,
      studentId: null,
      accountId: null,
      institutionId: null,
      classroomId: null,
      password: "redkey",
      avatar: null,
    },
  }),
  created() {
    //
    if (this.auth) {
      this.form.accountId = this.$_.clone(this.auth.Account.id);
      this.form.avatar = this.$_.clone(this.auth.Player.avatar);
      if (this.auth.Institution) {
        this.form.institutionId = this.$_.clone(this.auth.Institution.id);
      }
      if (this.auth.Classroom) {
        this.form.classroomId = this.$_.clone(this.auth.Classroom.id);
        this.form.studentId = this.$_.clone(this.auth.User.id);
      } else {
        this.form.userId = this.$_.clone(this.auth.User.id);
      }
    } else {
      this.$router.push({ name: "PageAccessMain" });
    }
    //BOC:[api]
    if (this.auth.Classroom) {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/player/add";
    } else {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/user/" +
        this.auth.User.id +
        "/player/add";
    }

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      var authData = this.$_.cloneDeep(this.auth);
      authData.Player = resp.Player;
      authData.Session = resp.Session;
      this.$store.commit("updateAuth", authData);
      this.$router.push({ name: "PageMainLogin" });
    };
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    exit() {
      this.$router.push({
        name: this.auth.Group.type == 'family' ? "PageAccessFamily" : "PageAccessClassroom",
      });
    },
    next() {
      if (this.selectedKey) {
        click_sfx_1.play();
        click_sfx_1.volume =
          this.settings.audio.sfx * this.settings.audio.master;
        this.api.params = this.$_.clone(this.form);
        this.$api.fetch(this.api);
      } else {
        click_sfx_2.play();
        click_sfx_2.volume =
          this.settings.audio.sfx * this.settings.audio.master;
        this.errorMessage = this.$t("string.selectKeyError");
        this.error = true;
      }
    },
    selectKey(value) {
      click_sfx_3.play();
      click_sfx_3.volume = this.settings.audio.sfx * this.settings.audio.master;
      this.selectedKey = value;
      this.form.password = this.selectedKey;
    },
    extiError() {
      this.error = false;
    },
  },
};
</script>
<style scoped>
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>